module.exports = {
  edito: ['faq-covid-19', 'april-courtage', 'avantage-assures', 'parrainage-recompense'],
  landing: [
    'mon-formulaire-changer-assurance-de-pret-immobilier',
    'ma-complementaire-sante-april-formulaire',
    'parrainage',
    'parrainage-april',
    'parrainage-april-filleul',
  ],
};
